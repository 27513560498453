/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { MdAccountCircle, MdShoppingBasket, MdExitToApp } from 'react-icons/md';
import { RiHeartFill } from 'react-icons/ri';
import { Divider, Link, Text } from '@/components/atoms';
import styles from './dropdown.module.scss';
import useLogout from '@/hooks/useLogout';

const Dropdown = (props) => {
  const { user, close } = props;
  const logout = useLogout();

  const CloseSesion = () => {
    logout();
    close(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.items}>
        <div className={styles.item}>
          <Text
            style={{ fontSize: '16px' }}
            textColor="var(--color-white-absolute)"
          >
            ¡Hola <span>{user}</span>!
          </Text>
        </div>
        <Divider color="var(--color-white-absolute)" />

        <div className={styles.item}>
          <Link href="/account?user=true">
            <MdAccountCircle
              size={20}
              className={styles.icon}
            />
            <Text
              style={{ fontSize: '15px' }}
              textColor="var(--color-white-absolute)"
            >
              Mi cuenta
            </Text>
          </Link>
        </div>

        <div className={styles.item}>
          <Link href="/account?orders=true">
            <MdShoppingBasket
              size={20}
              className={styles.icon}
            />
            <Text
              style={{ fontSize: '15px' }}
              textColor="var(--color-white-absolute)"
            >
              Mis pedidos
            </Text>
          </Link>
        </div>

        <div className={styles.item}>
          <Link href="/account?favorites=true">
            <RiHeartFill
              size={20}
              className={styles.icon}
            />
            <Text
              style={{ fontSize: '15px' }}
              textColor="var(--color-white-absolute)"
            >
              Mis favoritos
            </Text>
          </Link>
        </div>

        <div
          className={styles.item}
          role="button"
          onClick={CloseSesion}
        >
          <MdExitToApp
            size={20}
            className={styles.icon}
          />
          <Text
            style={{ fontSize: '15px' }}
            textColor="var(--color-white-absolute)"
          >
            Cerrar sesión
          </Text>
        </div>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  user: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default Dropdown;
