import { useState, useRef } from 'react';
import Router from 'next/router';
import { HiOutlineUser } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { Button, Icon } from '@/components/atoms';
import useOutsideClick from '@/hooks/useOutsideClick';
import Dropdown from './Dropdown';
import useDeviceType from '@/hooks/useDeviceType';
import styles from './index.module.scss';
import pushToMicroSite from '@/utils/pushToMicroSite';

const UserIcon = () => {
  const checkout = useSelector((state) => state.checkout);
  const [open, setOpen] = useState(false);
  const { isDesktop } = useDeviceType();

  const {
    logged,
    personalInfo: { first_name: firstName }
  } = checkout;

  const ref = useRef(null);

  useOutsideClick(
    ref,
    () => {
      if (open) {
        setOpen(false);
      }
    },
    open
  );

  const onClick = () => {
    if (!checkout.logged) Router.push(`${pushToMicroSite()}/login`);
    else setOpen(!open);
  };

  return (
    <div
      ref={ref}
      className={styles.userIcon}
    >
      {isDesktop ? (
        <Button
          id="loginStatusButton"
          onClick={onClick}
          style={{ height: '2.5rem' }}
          label={logged ? firstName : 'Mi Cuenta'}
          textStyle={{ whiteSpace: 'nowrap' }}
          variant="transparent"
          border="var(--color-white-absolute)"
          textColor="var(--color-white-absolute)"
          br="hard"
          withOutPadding
          startIcon={
            <HiOutlineUser
              color="var(--color-white-absolute)"
              size={20}
            />
          }
        />
      ) : (
        <Button
          id="loginStatusButton"
          onClick={onClick}
          style={{ height: '2.5rem' }}
          variant="transparent"
          withOutPadding
          ariaLabel="Mi cuenta"
          startIcon={
            <HiOutlineUser
              aria-hidden="true"
              color="var(--color-white-absolute)"
              size={30}
            />
          }
          endIcon={
            logged && (
              <Icon
                type="online"
                className={styles.online}
                width=".5rem"
              />
            )
          }
        />
      )}

      {open && (
        <Dropdown
          user={checkout.personalInfo.first_name}
          close={setOpen}
        />
      )}
    </div>
  );
};

export default UserIcon;
